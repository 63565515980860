export default class UserService {
  constructor(args) {
    this.axios = args.$axios
  }

  getUserProfile() {
    return this.axios.get(`profile?include[]=card&include[]=subscription&include[]=app_ratings`)
  }

  fetchConnectedApps() {
    return this.axios.get(`apps/connected`)
  }

  fetchUserRatings() {
    return this.axios.get(`show-rating-popup`)
  }

  completeOnboarding({ first_name, last_name, password, estimated_traffic, web_role }) {
    return this.axios.post(`onboarding`, {
      first_name,
      last_name,
      ...(password && { password }),
      ...(estimated_traffic && { estimated_traffic }),
      ...(web_role && { web_role })
    })
  }

  updateUserProfile({ email, first_name, last_name, address, vat, country_code, company_name }) {
    return this.axios.patch(`profile`, {
      email,
      first_name,
      last_name,
      address,
      vat,
      country_code,
      company_name
    })
  }

  updateUserEmail(email) {
    return this.axios.patch(`profile`, {
      email
    })
  }

  updateUserPhoto(url) {
    return this.axios.patch(`profile/photo`, {
      profile_photo_url: url
    })
  }

  hidePaymentFailedModal() {
    return this.axios.post(`reminder/failed-payment`, {})
  }

  deleteUserPhoto() {
    return this.axios.delete(`profile/photo`)
  }

  skipOnboarding() {
    return this.axios.delete(`onboarding`)
  }

  verifyEmail(props) {
    const { id, token, expires, signature } = props
    return this.axios.get(`email/verify/${id}/${token}?expires=${expires}&signature=${signature}`)
  }

  resendVerificationEmail() {
    return this.axios.post(`email/verify/resend`)
  }
}
