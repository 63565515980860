<template>
  <div class="chaty-action__wrapper" v-if="widget">
    <div class="dropdown" :class="{ active: isOpen }">
      <span class="action-button" :class="widget.state == 'active' ? '' : 'is--draft'">
        <a href="#" @click.prevent="editWidget" class="nav__item">
          {{ widget.state == 'active' ? $t('widgets.edit') : $t('widgets.finish_editing') }}
        </a>
        <span class="dropdown" @click.stop="isOpen = !isOpen" v-on-clickaway="toggleState">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 8.667a.667.667 0 100-1.334.667.667 0 000 1.334zM8 4a.667.667 0 100-1.333A.667.667 0 008 4zM8 13.333A.667.667 0 108 12a.667.667 0 000 1.333z" stroke="currentColor" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </span>
      </span>

      <transition name="slow">
        <div class="dropdown__body" v-if="isOpen">
          <ul>
            <li>
              <a href="#" @click.prevent="$refs.duplicateWidgetModal.open()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333z" stroke="currentColor" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.333 10h-.666a1.333 1.333 0 01-1.334-1.333v-6a1.333 1.333 0 011.334-1.334h6A1.333 1.333 0 0110 2.667v.666" stroke="currentColor" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('widget_actions.duplicate') }}
              </a>
            </li>
            <li>
              <a href="#" @click.prevent="$refs.renameWidgetModal.open()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.166 2.5A2.357 2.357 0 0117.5 5.833L6.25 17.083l-4.583 1.25 1.25-4.583L14.166 2.5z" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('widget_actions.rename') }}
              </a>
            </li>
            <li>
              <a href="#" @click.prevent="$refs.changeDomainModal.open()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM1.667 10h16.666" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 1.667A12.75 12.75 0 0113.333 10 12.75 12.75 0 0110 18.333 12.75 12.75 0 016.667 10 12.75 12.75 0 0110 1.667v0z" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('widget_actions.change_domain') }}
              </a>
            </li>
            <li v-if="showChatwayConnection && !hasChatwayAccount">
              <a href="#" @click.prevent="$refs.chatwayConnectModal.open()">
                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.367 22.703l1.637-2.863 1.187 1.859s-.754-.134-1.44.194c-.687.328-1.384.81-1.384.81z" fill="#648AA5"/><path d="M6.193 21.342a.97.97 0 00-.712-.564l-3.894-.72A1.94 1.94 0 010 18.153V6.534c0-1.43.7-2.77 1.876-3.585L4.39 1.205A4.606 4.606 0 017.798.45l8.982 1.548A3.879 3.879 0 0120 5.821v8.8c0 1.043-.42 2.04-1.163 2.77l-3.199 3.138a5.091 5.091 0 01-4.603 1.349l-1.858-.387a.97.97 0 00-.906.286l-.782.836a.485.485 0 01-.798-.137l-.498-1.134z" fill="#83A1B7"/><path d="M4.263 4.353A3.152 3.152 0 00.484 7.442v9.924a1.94 1.94 0 001.587 1.907l3.857.714a.97.97 0 01.72.582l.345.832c.105.253.44.303.616.094l.667-.801a.97.97 0 01.818-.346l3.068.232a2.667 2.667 0 002.868-2.659V8.126a1.94 1.94 0 00-1.553-1.9L4.263 4.352z" fill="#648AA5"/><path d="M4.055 4.34a1.94 1.94 0 00-2.309 1.905v10.204a1.94 1.94 0 001.662 1.92l2.646.383a.97.97 0 01.739.546l.371.789a.364.364 0 00.614.098l.56-.65a.97.97 0 01.874-.327l3.601.521a1.94 1.94 0 002.217-1.92V8.07a1.94 1.94 0 00-1.57-1.904L4.055 4.34z" fill="#fff"/><path d="M10.91 14.59l-5.6-.981c-.406-.072-.738.254-.58.636.512 1.242 1.941 3.195 4.474 2.544.521-.134 1.7-.812 2.033-1.715.083-.226-.091-.442-.328-.484z" fill="#83A1B7"/><ellipse cx="5.503" cy="9.962" rx=".993" ry="1.702" transform="rotate(-4.903 5.503 9.962)" fill="#83A1B7"/><ellipse cx="10.749" cy="10.931" rx=".993" ry="1.702" transform="rotate(-4.903 10.749 10.93)" fill="#83A1B7"/></svg>
                {{ $t('widget_actions.live_chat') }}
              </a>
            </li>
            <li class="action-separator"></li>
            <li class="remove--button">
              <a href="#" @click.prevent="$refs.deleteWidgetModal.open()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.666a1.333 1.333 0 011.334 1.334V4m2 0v9.333a1.334 1.334 0 01-1.334 1.334H4.667a1.334 1.334 0 01-1.334-1.334V4h9.334z" stroke="#49687E" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('widget_actions.delete') }}
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <DuplicateWidgetModal ref="duplicateWidgetModal" :name="widget.name" :domain="domain" :id="widget.identifier" />
    <RenameWidgetModal ref="renameWidgetModal" :name="widget.name" :id="widget.identifier" />
    <ChangeDomainModal ref="changeDomainModal" :name="widget.name" :id="widget.identifier" />
    <DeleteWidgetModal ref="deleteWidgetModal" :name="widget.name" :id="widget.identifier" />
    <ChatwayConnectModal ref="chatwayConnectModal" />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'

import DuplicateWidgetModal from '@/components/modals/DuplicateWidget.vue'
import RenameWidgetModal from '@/components/modals/RenameWidget.vue'
import ChangeDomainModal from '@/components/modals/ChangeDomain.vue'
import DeleteWidgetModal from '@/components/modals/DeleteWidget.vue'
import ChatwayConnectModal from '@/components/modals/ChatwayConnectModal.vue'

export default {
  mixins: [clickaway],
  components: {
    DuplicateWidgetModal,
    RenameWidgetModal,
    ChangeDomainModal,
    DeleteWidgetModal,
    ChatwayConnectModal
  },
  props: {
    widget: {
      type: Object,
      default: null
    },
    domain: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      widgets: 'widget/getDomains'
    })
  },

  methods: {
    toggleState() {
      this.isOpen = false
    },
    editWidget() {
      this.$router.push('/update-widget/' + this.widget.identifier)
      this.logEvent('start_edit')
    }
  }
}
</script>

<style></style>
