<template>
  <div class="root">
    <div class="auth-layout">
      <div class="main-area" :class="{ 'is--loading': isLoading }">
        <router-view @toggleLoading="toggleLoading"></router-view>
        <div class="footer-area">
          <div class="companies">
            <div class="item">
              <img width="94" height="30" loading="lazy" src="/images/logo/navia.webp" alt="navia" />
            </div>
            <div class="item">
              <img width="121" height="31" loading="lazy" src="/images/logo/swiss.webp" alt="swiss" />
            </div>
            <div class="item">
              <img width="103" height="27" loading="lazy" src="/images/logo/appshally.webp" alt="app-shally" />
            </div>
            <div class="item">
              <img width="116" height="40" loading="lazy" src="/images/logo/caribbean.webp" alt="caribbean" />
            </div>
            <div class="item">
              <img width="98" height="29" loading="lazy" src="/images/logo/string.webp" alt="string" />
            </div>
          </div>
        </div>
        <div class="spinner">
          <div class="loader"></div>
        </div>
      </div>
      <div class="support-area">
        <div>
          <div class="support-text-area">
            <div class="support-text first-text">
              <h5>
                {{ $t('auth.websites_using_chaty', { customers: '200,000+' }) }}
              </h5>
            </div>

            <div class="support-text first-text">
              <h5>
                {{ $t('auth.customer_reviews', { reviews: '1,000+' }) }}
              </h5>
            </div>
          </div>

          <div class="testimonial-area">
            <div class="thumbnail">
              <img width="109" height="109" loading="lazy" src="/images/thumbnails/auth-thumbnail.webp" alt="auth thumbnail" />
            </div>
            <div class="text">
              <p>{{ $t('auth.testimonial') }}</p>
              <div class="author">{{ $t('auth.author') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    toggleLoading(val) {
      this.isLoading = val
    }
  }
}
</script>

<style></style>
