export default {
  namespaced: true,

  state: () => ({
    domains: null,
    widgetCreation: null,
    widgetCreationChannels: null,
    widgetCreationSettings: null,
    widgetCreationTriggers: null,
    widgetName: '',
    isNew: false,
    hasSaved: false,
    isFetchingWidget: true,
    error: null,
    squareStores: null
  }),

  mutations: {
    updateDomains(state, response) {
      // accounting for null
      if (!response) {
        state.domains = response
        return
      }

      let domains = []
      if (!response.data.length) {
        return (state.domains = [])
      }

      response.data.forEach(element => {
        const domain = { ...element.attributes }
        domains.push(domain)
      })

      domains = domains.map(domain => {
        domain.widget = domain.widget.sort((a, b) => {
          // Sort widgets by ID desc
          return b.id - a.id
        })

        return domain
      })
      state.domains = [...domains]
    },

    updateWidget(state, widgetCreation) {
      state.widgetCreation = widgetCreation
    },
    updateWidgetChannels(state, widgetCreationChannels) {
      state.widgetCreationChannels = widgetCreationChannels
    },
    updateWidgetTriggers(state, widgetCreationTriggers) {
      state.widgetCreationTriggers = widgetCreationTriggers
    },
    updateWidgetSettings(state, widgetCreationSettings) {
      state.widgetCreationSettings = widgetCreationSettings
    },
    updateWidgetName(state, widgetName) {
      state.widgetName = widgetName
    },
    updateWidgetNewStatus(state, isNew) {
      state.isNew = isNew
    },
    updateWidgetSaveStatus(state, hasSaved) {
      state.hasSaved = hasSaved
    },
    updateWidgetFetchStatus(state, isFetchingWidget) {
      state.isFetchingWidget = isFetchingWidget
    },
    updateError(state, error) {
      state.error = error
    },

    updateSquareShop(state, squareStores) {
      state.squareStores = squareStores
    },

    clearWidgetTriggers(state) {
      if (state.widgetCreationTriggers && state.widgetCreationTriggers.show_on_pages_rules) {
        state.widgetCreationTriggers.show_on_pages_rules.length = 0
      }
      if (state.widgetCreationTriggers && state.widgetCreationTriggers.day_hours_scheduling_rules) {
        state.widgetCreationTriggers.day_hours_scheduling_rules.length = 0
      }
      if (state.widgetCreationTriggers && state.widgetCreationTriggers.show_on_referrer_urls) {
        state.widgetCreationTriggers.show_on_referrer_urls.length = 0
      }
      state.widgetCreationTriggers = null
    }
  },

  getters: {
    getDomains({ domains }) {
      return domains
    },
    getWidget({ widgetCreation }) {
      return widgetCreation
    },
    getWidgetCreationChannels({ widgetCreationChannels }) {
      return widgetCreationChannels
    },
    getWidgetCreationTriggers({ widgetCreationTriggers }) {
      return widgetCreationTriggers
    },
    getWidgetCreationSettings({ widgetCreationSettings }) {
      return widgetCreationSettings
    },
    getWidgetNewStatus({ isNew }) {
      return isNew
    },
    getWidgetSaveStatus({ hasSaved }) {
      return hasSaved
    },
    getWidgetFetchStatus({ isFetchingWidget }) {
      return isFetchingWidget
    },
    getError({ error }) {
      return error
    },
    getSquareShop({ squareStores }) {
      return squareStores
    }
  }
}
