<template>
  <sweet-modal overlay-theme="dark" class="ch-modal youtube--modal" ref="modal" @close="resetForm">
    <div class="sweet-content__content">
      <div class="modal-details">
        <div class="youtube-title">{{ $t('widgets.quick_tour') }}</div>
        <div class="youtube-desc">{{ $t('widgets.quick_tour_desc') }}</div>
        <div class="youtube-container" v-html="youtube_iframe"></div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
export default {
  name: 'YouTubeModal',
  data() {
    return {
      youtube_iframe: ''
    }
  },

  methods: {
    open() {
      this.youtube_iframe =
        '<iframe class="responsive-iframe" width="590" height="332" src="https://www.youtube.com/embed/QeyYY3qWgDw?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      this.$refs.modal.open()
    },
    resetForm() {
      this.youtube_iframe = ''
    }
  }
}
</script>

<style scoped>
.sweet-modal {
  overflow-x: hidden;
}
</style>
